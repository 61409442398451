import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Outsource from "../components/e-Commerce/Outsource"
import AccordianTech from "../components/on-demand/AccordianTech"
import Banner from "../components/on-demand/Banner"
import Idea from "../components/on-demand/Idea"
import Idea2 from "../components/on-demand/Idea2"
import Industries from "../components/on-demand/Industries"
import LatestFeatures from "../components/on-demand/LatestFeatures"
import MobServices from "../components/on-demand/MobServices"
import MobileApplication from "../components/on-demand/MobileApplication"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Services from "../components/on-demand/Services"
import Solutions from "../components/on-demand/Solutions"
import Technologies from "../components/on-demand/Technologies"
import Testimonials from "../components/on-demand/Testimonials"
import Testimonials2 from "../components/on-demand/Testimonials2"
import TimeLine from "../components/on-demand/TimeLine"
import TopFeature from "../components/on-demand/TopFeature"
import Ugly from "../components/on-demand/Ugly"
import MainLayout from "../layouts/MainLayout"
import "../components/on-demand/ondemand.scss"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
    />
  )
}
const onDemand = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const tech = [data?.strapiPage?.sections[7], data?.strapiPage?.sections[8]]

  const heroSection = data?.strapiPage?.sections[0]
  const services = data?.strapiPage?.sections[1]
  const feature = data?.strapiPage?.sections[2]
  const idea = data?.strapiPage?.sections[3]
  const timeline = data?.strapiPage?.sections[4]
  const technologies = data?.strapiPage?.sections[7]
  const outsource = data?.strapiPage?.sections[5]
  const industries = data?.strapiPage?.sections[6]
  const benefits = data?.strapiPage?.sections[7]
  const solutions = data?.strapiPage?.sections[8]
  const emgagment = data?.strapiPage?.sections[9]
  const features = data?.strapiPage?.sections[10]
  const ugly = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]

  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      {!isMobile && !isTablet ? (
        <Services strapiData={services} />
      ) : (
        <MobServices strapiData={services} />
      )}

      <TopFeature strapiData={feature} />
      {!isMobile && !isTablet ? (
        <Idea strapiData={idea} />
      ) : (
        <Idea2 strapiData={idea} />
      )}
      {!isMobile && !isTablet ? (
        <TimeLine strapiData={timeline} />
      ) : (
        <MobileApplication strapiData={timeline} />
      )}
      <Outsource strapiData={outsource} pageName="On Demand Services" />
      <Industries strapiData={industries} />
      {!isMobile && !isTablet ? (
        <Technologies strapiData={technologies} />
      ) : (
        <AccordianTech strapiData={technologies} />
      )}
      <Solutions strapiData={solutions} />
      <div className="paddingEngagement">
        <EngagementModels strapiData={emgagment} />
      </div>
      <LatestFeatures strapiData={features} />
      <WorkPortfolio
        portfolioHead={"Portfolio"}
        portfolioDesc={
          "Our diverse portfolio is the manifestation of our exceptional services."
        }
      />
      {/* <OnDemandPortfolio /> */}
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Ugly strapiData={ugly} />
      <Faqs strapiData={faqs} pageName="On Demand Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "on-demand-services-page-1" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default onDemand
