// extracted by mini-css-extract-plugin
export var cardBg = "Technologies-module--cardBg--a8fa9";
export var cards = "Technologies-module--cards--f885a";
export var cardsContent = "Technologies-module--cardsContent--a16b4";
export var description = "Technologies-module--description--f94f4";
export var globalIndus = "Technologies-module--globalIndus--4e7cd";
export var heading = "Technologies-module--heading--3a486";
export var iconssCard = "Technologies-module--iconssCard--036f2";
export var nav = "Technologies-module--nav--9f466";
export var navItem = "Technologies-module--nav-item--20f06";
export var navbarBlock = "Technologies-module--navbarBlock--9f5c6";
export var tabData = "Technologies-module--tabData--708eb";
export var tech = "Technologies-module--tech--40339";
export var techIcon = "Technologies-module--techIcon--b5546";
export var techImg = "Technologies-module--techImg--0a402";
export var technologyIcon = "Technologies-module--technologyIcon--62c9c";