import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Idea.module.scss"

const Idea = ({ strapiData }) => {
  return (
    <Container className={`${styles.mainContainer}`}>
      <Row className={`gx-0 ${styles.mainRow}`}>
        <Col lg={6}>
          <h2
            className={`${styles.mainHead}`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={`${styles.description}`}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Link
            to={strapiData?.buttons[0]?.url}
            className={`${styles.idea_btn}`}
          >
            {strapiData?.buttons[0]?.title}
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Idea
