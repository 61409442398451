// extracted by mini-css-extract-plugin
export var btn = "Solutions-module--btn--5a344";
export var cards = "Solutions-module--cards--6c0ca";
export var description = "Solutions-module--description--99eac";
export var hire = "Solutions-module--hire--8cba8";
export var hireCyberonDemand = "Solutions-module--hireCyberonDemand--33601";
export var hireImg = "Solutions-module--hireImg--d01dc";
export var talent = "Solutions-module--talent--c1bd6";
export var teamBtn = "Solutions-module--teamBtn--d00c7";
export var text = "Solutions-module--text--f7a25";
export var tick = "Solutions-module--tick--2f4bf";