// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--e369f";
export var clientDots2 = "Testimonials-module--clientDots2--10e26";
export var content = "Testimonials-module--content--513e4";
export var dots3 = "Testimonials-module--dots3--12afa";
export var hireArrow = "Testimonials-module--hireArrow--71e13";
export var iconContainer = "Testimonials-module--iconContainer--85be7";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--11ef0";
export var imgBtn = "Testimonials-module--imgBtn--52f52";
export var person = "Testimonials-module--person--f6a5a";
export var personBg = "Testimonials-module--personBg--f41bd";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--d5335";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--81d5c";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--93974";
export var row = "Testimonials-module--row--b39ad";
export var section = "Testimonials-module--section--b51e3";
export var staffRec = "Testimonials-module--staffRec--23736";
export var testName = "Testimonials-module--testName--0c321";
export var testimonials = "Testimonials-module--testimonials--0acd3";
export var testimonialsContent = "Testimonials-module--testimonialsContent--18b3f";
export var vector2 = "Testimonials-module--vector2--d9d01";