// extracted by mini-css-extract-plugin
export var btn = "LatestFeatures-module--btn--f4caf";
export var cards = "LatestFeatures-module--cards--34b66";
export var description = "LatestFeatures-module--description--c8a45";
export var hire = "LatestFeatures-module--hire--0fd55";
export var hireCyberonDemand = "LatestFeatures-module--hireCyberonDemand--d88f0";
export var hireImg = "LatestFeatures-module--hireImg--3740e";
export var latestFeaturesBtn = "LatestFeatures-module--latestFeaturesBtn--ecb2f";
export var subTitle = "LatestFeatures-module--subTitle--90eeb";
export var talent = "LatestFeatures-module--talent--e625c";
export var text = "LatestFeatures-module--text--33f12";
export var tick = "LatestFeatures-module--tick--93b75";