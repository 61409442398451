import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import idea2Rocket from "../../images/on-demand/idea2Rocket.svg"
import * as styles from "./Idea2.module.scss"

const Idea2 = ({ strapiData }) => {
  return (
    <div className={styles.mobContainer}>
      <Container>
        <Row className={styles.mainRow}>
          <Col lg={12} md={12} xs={12} sm={12} className="text-center">
            <h2
              className={`${styles.mainHead}`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={`${styles.description}`}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <Link
              to={strapiData?.buttons[0]?.url}
              className={`${styles.idea_btn}`}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          </Col>
          <Col xl={12} md={12} sm={12} xs={12} className="text-center pt-5">
            <img src={idea2Rocket} alt="Idea To MVP In 4 Weeks" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Idea2
