import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Industries.module.scss"

const Industries = ({ strapiData }) => {
  return (
    <div className={`${styles.servicesDemand}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={3} md={6} sm={6} xs={11} key={i} className="gap-30">
              <div className={styles.card}>
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  decoding="async"
                  loading="lazy"
                  alt={e?.title}
                />
                <h3>{e?.title}</h3>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Industries
