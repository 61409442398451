// extracted by mini-css-extract-plugin
export var btn = "Ugly-module--btn--10201";
export var cards = "Ugly-module--cards--209a9";
export var description = "Ugly-module--description--f4cb4";
export var hireDemand = "Ugly-module--hireDemand--d7395";
export var hireImgUgly = "Ugly-module--hireImgUgly--c25a0";
export var subTitle = "Ugly-module--subTitle--bf3f5";
export var talent = "Ugly-module--talent--c8a8b";
export var text = "Ugly-module--text--27938";
export var tick = "Ugly-module--tick--6831a";
export var uglyLeft = "Ugly-module--uglyLeft--d049a";
export var uglyOnDemand = "Ugly-module--uglyOnDemand--a8d06";
export var uglyTeamBtn = "Ugly-module--uglyTeamBtn--f5ef5";