// extracted by mini-css-extract-plugin
export var SliderWidth = "Services-module--SliderWidth--86564";
export var arrowImg = "Services-module--arrowImg--049cb";
export var card = "Services-module--card--2c98e";
export var cardWrapper = "Services-module--cardWrapper--35421";
export var cards = "Services-module--cards--08a28";
export var description = "Services-module--description--8d692";
export var heading = "Services-module--heading--76cde";
export var headingPremium = "Services-module--headingPremium--6d618";
export var iconContainer = "Services-module--iconContainer--03a7c";
export var iconContainerLeft = "Services-module--iconContainerLeft--07297";
export var onDemandmainWork = "Services-module--onDemandmainWork--8db37";
export var portfolioArrowIcon = "Services-module--portfolioArrowIcon--feae3";
export var portfolioArrowIconCover = "Services-module--portfolioArrowIconCover--4d147";
export var portfolioArrowRightIconCover = "Services-module--portfolioArrowRightIconCover--884ce";
export var providingImg = "Services-module--providingImg--83186";
export var trailBg = "Services-module--trailBg--f204f";