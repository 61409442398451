// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--9077d";
export var circleTes = "Testimonials2-module--circleTes--bf4dd";
export var content = "Testimonials2-module--content--7ae88";
export var des = "Testimonials2-module--des--ff62b";
export var iconContainer = "Testimonials2-module--iconContainer--f8646";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--6ee3a";
export var personBg = "Testimonials2-module--personBg--79b94";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--c4f9a";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--e1fc1";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--4d73e";
export var row = "Testimonials2-module--row--4ba9c";
export var section = "Testimonials2-module--section--0d631";
export var tesBtn = "Testimonials2-module--tesBtn--215b9";
export var tesDots = "Testimonials2-module--tesDots--8efb4";
export var tesRec = "Testimonials2-module--tesRec--707cd";
export var testimonials = "Testimonials2-module--testimonials--40168";