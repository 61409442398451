import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./Technologies.module.scss"

const data = [
  {
    title: "iOS Apps",
    tech: [
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.amazonaws.com/swift_09fad37072.svg",
      },
      {
        name: "Xcode",
        icon: "https://invozone-backend.s3.amazonaws.com/xcode_18c162bda2.svg",
      },
      {
        name: "iOS SDK",
        icon: "https://invozone-backend.s3.amazonaws.com/ios_sdk_c771f6d8ff.svg",
      },
      {
        name: "UIKit",
        icon: "https://invozone-backend.s3.amazonaws.com/uikit_1c4a70ee69.svg",
      },
    ],
  },
  {
    title: "Android Apps",
    tech: [
      {
        name: "Java",
        icon: "https://invozone-backend.s3.amazonaws.com/java_7465739d6b.svg",
      },
      {
        name: "Android Studio",
        icon: "https://invozone-backend.s3.amazonaws.com/android_studio_fa5892acb8.svg",
      },
      {
        name: "Android SDK",
        icon: "https://invozone-backend.s3.amazonaws.com/android_sdk_25367fcb48.svg",
      },
      {
        name: "Android framework",
        icon: "https://invozone-backend.s3.amazonaws.com/android_framework_7aac57218d.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.amazonaws.com/kotlin_481578eca3.svg",
      },
    ],
  },
  {
    title: "Cross-Platform App Development",
    tech: [
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.amazonaws.com/html_8405277d25.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.amazonaws.com/css_fcc4755501.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.amazonaws.com/javascript_d3c07956a3.svg",
      },
      {
        name: "Cordova",
        icon: "https://invozone-backend.s3.amazonaws.com/cordova_d849798889.svg",
      },
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.amazonaws.com/ionic_b34e3d6cbe.svg",
      },
    ],
  },
  {
    title: "Hybrid App Development",
    tech: [
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.amazonaws.com/flutter_0c147017d5.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.amazonaws.com/react_native_7aa039a23a.svg",
      },
      {
        name: "Xamarin",
        icon: "https://invozone-backend.s3.amazonaws.com/xamarin_10feeacf85.svg",
      },
    ],
  },
]

const Technologies = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="onDemandTab">
      <div className={styles.globalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`onDemandTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Technologies
